<script>
  import axios from 'axios';
  import { consumptionInputValidation } from './utils/consumption-validation.utils';

  const GROW_TYPE_MAP = {
    indoor: 'interior',
    outdoor: 'exterior',
    indoorOutdoor: 'interior y exterior ',
  };

  const DEFAULT_CONSUMPTION = '5.5';

  let authorizedConsumption = DEFAULT_CONSUMPTION;
  let growType = 'indoor';

  let indoor = 25;
  let outdoor = 0;
  let storage = 1125;
  let btnLabel = 'Calcular';
  let enableDownloadBtn = false;
  let authorization = '';
  let id = '';

  let btnIdLabel = 'Buscar';
  let userId = '';

  export let copiedText = false;

  const onSubmit = async () => {
    btnLabel = 'Calculando ...';
    copiedText = false;

    const quantity = consumptionInputValidation(authorizedConsumption);

    try {
      const response = await axios.post('https://pavg.xyz/calculator', {
        type: growType,
        quantity: quantity,
      });

      const { data, headers } = response;

      authorization = headers['authorization'];

      indoor = data.indoor;
      outdoor = data.outdoor;
      storage = data.storage;
      userId = data.id;
    } catch (err) {
      console.log(err);

      throw err;
    }

    btnLabel = 'Calcular';
    enableDownloadBtn = true;
  };

  const handleDownload = async () => {
    const quantity = consumptionInputValidation(authorizedConsumption);

    try {
      const { data } = await axios.post(
        'https://pavg.xyz/calculator/pdf',
        {
          type: growType,
          quantity: quantity,
        },
        {
          headers: {
            authorization,
          },
          responseType: 'arraybuffer',
        }
      );

      enableDownloadBtn = false;

      const blob = new Blob([data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);

      window.open(url);
      location.reload();
    } catch (err) {
      console.log(err);

      throw err;
    }
  };

  const submitId = async () => {
    copiedText = false;
    btnIdLabel = 'Buscando ...';

    try {
      const response = await axios.get(`https://pavg.xyz/calculator/${id}`, {
        headers: {
          authorization,
        },
      });

      const { status, data, headers } = response;

      if (status !== 200) {
        throw new Error('Data not found');
      }

      userId = id;

      // new results to return on screen
      indoor = data.indoor;
      outdoor = data.outdoor;
      storage = data.storage;
      authorizedConsumption = data.quantity;
      growType = data.type;
      authorization = headers['authorization'];
      enableDownloadBtn = true;

      btnIdLabel = 'Buscar';
    } catch (err) {
      id = '';
      btnIdLabel = 'Buscar';
      throw err;
    }
  };

  const handleCopyText = async () => {
    let element = document.getElementById('copy_clipboard');
    let copyId = element.innerHTML;

    try {
      await navigator.clipboard.writeText(copyId);
      copiedText = true;
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };
</script>

<template>
  <div class="calc__wrapper">
    <div class="box">
      <div class="previous__results">
        <form class="form__id" on:submit|preventDefault={submitId}>
          <h1>Buscar resultados</h1>
          <div>
            <input
              class="form__id--input"
              type="text"
              id="search_id"
              name="search"
              placeholder="Ingresar numero"
              bind:value={id}
            />
            <button id="button__id" type="submit" class="btn btn__id">
              {btnIdLabel}
            </button>
          </div>
        </form>
      </div>
      <div class="separator" />
      <form on:submit|preventDefault={onSubmit}>
        <div class="form__wrapper">
          <h1>Calculadora</h1>
          <p>
            Por favor ingresa tus gramos diarios autorizados y selecciona {GROW_TYPE_MAP.indoor}
            y/o {GROW_TYPE_MAP.outdoor}
          </p>
          <div class="input">
            <label for=""> Gramos diarios autorizados </label>
            <input
              type="text"
              placeholder="5"
              bind:value={authorizedConsumption}
            />
          </div>
          <div class="use__wrapper">
            <p>Tipo de cultivo</p>
            <div class="input__radio">
              <input
                type="radio"
                id="indoor"
                value="indoor"
                name="input-grow-type"
                checked
                bind:group={growType}
              />
              <label for="indoor"> {GROW_TYPE_MAP.indoor} </label>
            </div>
            <div class="input__radio">
              <input
                type="radio"
                id="outdoor"
                value="outdoor"
                name="input-grow-type"
                bind:group={growType}
              />
              <label for="outdoor"> {GROW_TYPE_MAP.outdoor} </label>
            </div>
            <div class="input__radio">
              <input
                type="radio"
                id="indoor-outdoor"
                value="indoorOutdoor"
                name="input-grow-type"
                bind:group={growType}
              />
              <label for="indoor-outdoor">
                {GROW_TYPE_MAP.indoorOutdoor}
              </label>
            </div>
          </div>
        </div>
        <div class="result__wrapper">
          <div class="result">
            <span class="text"> Para </span>
            <span class="quantity"> {authorizedConsumption} gramos</span>
            <span class="text">
              bajo cultivo interior y/o exterior puedes tener:
            </span>
            <div class="final__result">
              <div class="final__result__box">
                <span class="quantity"> {indoor}</span>
                <span> plantas de {GROW_TYPE_MAP.indoor} </span>
              </div>
              <div class="final__result__box">
                <span class="quantity"> {outdoor} </span>
                <span> plantas de {GROW_TYPE_MAP.outdoor} </span>
              </div>
              <div class="final__result__box">
                <span class="quantity"> {storage} </span>
                <span> gramos almacenados </span>
              </div>
            </div>
          </div>
          <div class="submit__wrapper">
            <button class="btn" type="submit"> {btnLabel} </button>
          </div>
        </div>
      </form>
      <div class="bottom__content">
        {#if userId}
          <div class="result__id">
            <span> Tu numero de consulta es el:</span>
            {#if copiedText}
              <p class="copied_text">Copiado con exito 👍</p>
            {:else}
              <code id="copy_clipboard" on:mouseup={handleCopyText}
                >{userId}</code
              >
            {/if}
          </div>
        {/if}
        <button
          class="btn btn__download {enableDownloadBtn ? '' : 'btn--disable'}"
          on:click={handleDownload}
        >
          Descargar PDF
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .calc__wrapper {
    width: 100%;
    justify-content: center;
    font-family: 'Lato', Helvetica, sans-serif;

    @media (min-width: 800px) {
      display: flex;
    }

    .box {
      padding: 2rem;
      width: auto;

      @media (min-width: 800px) {
        width: 800px;
        border: 1px solid rgba(0, 0, 0, 0.1);
      }
    }

    form {
      display: flex;
      flex-direction: column;

      @media (min-width: 800px) {
        flex-direction: row;
      }
    }

    h1 {
      margin: 0;
    }
  }

  .separator {
    margin: 2rem 0;
    border-bottom: 1px solid #cfd8dc;
  }

  .use__wrapper {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    padding-top: 2rem;
  }

  .form__wrapper {
    padding-right: 2rem;
    margin-bottom: 2rem;

    @media (min-width: 800px) {
      width: 50%;
      margin-bottom: 0;
    }
  }

  .form__id {
    display: flex;
    flex-direction: row;

    h1 {
      @media (min-width: 800px) {
        width: calc(50% + 2rem);
      }
    }

    div {
      display: flex;
      width: 100%;

      @media (min-width: 800px) {
        width: 50%;
      }
    }

    @media (width < 800px) {
      flex-direction: column;
      gap: 1rem;
    }
  }

  .form__id--input {
    width: 100%;
    border: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: 2px solid #2a7c6e79;
    font-size: 14px;
    padding: 1rem 0.5rem;

    @media (width < 600px) {
      width: 10rem;
    }

    @media (width < 800px) {
      padding: 0.8rem;
    }
  }

  .form__id--input:is(:focus)::placeholder {
    color: #2a7c6e79;
  }
  #search_id:focus,
  #search_id:active {
    outline: none;
    border: 2px solid #2a7c6f;
  }

  .btn.btn__id {
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    // @media (width < 800px) {
    //   border-radius: 0px;
    // }
  }

  .form__wrapper,
  .result__wrapper {
    width: 100%;
    height: 100%;

    @media (min-width: 800px) {
      width: 50%;
    }
  }

  .result {
    border-radius: 5px;
    border: 1px solid #cfd8dc;
    height: 270px;
    padding: 2rem;
    color: #ffffff;
    background-color: #2a7c6f;
    .text {
      display: block;
    }

    .quantity {
      font-size: 1.6rem;
      padding: 0.5rem 0;
      padding-right: 1rem;

      @media (min-width: 800px) {
        font-size: 2rem;
      }
    }
  }

  .final__result {
    padding-top: 1rem;
  }

  .final__result__box {
    display: flex;
    align-content: center;
    align-items: center;

    span {
      padding-right: 1rem;
    }
  }

  .bottom__content {
    display: flex;
    position: relative;
    width: 100%;
    justify-content: flex-end;
  }

  .result__id {
    position: absolute;
    left: 0;

    #copy_clipboard {
      display: block;
      padding-top: 1rem;
      cursor: pointer;
    }

    & > p {
      margin: 0;
      cursor: pointer;
      transition: all 3s ease-in-out;
    }

    p.copied_text {
      padding-top: 0.5rem;
      color: #2a7c6f;
    }
  }

  .submit__wrapper {
    width: 100%;
    padding-top: 2rem;
    text-align: right;
  }

  .btn.btn__download {
    color: #2a7c6f;
    background-color: #ffffff;
    border: 2px solid #2a7c6f;
    width: 100%;

    &:hover {
      color: #ffffff;
    }

    @media (min-width: 800px) {
      width: 48%;
    }

    &.btn--disable {
      opacity: 0.2;
      background-color: #cfd8dc;
      pointer-events: none;
    }
  }

  .btn {
    width: 100%;
    padding: 1rem;
    border: none;
    border-radius: 4px;
    background-color: #2a7c6f;
    text-transform: uppercase;
    font-size: 1rem;
    color: #ffffff;
    font-weight: 400;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);

    &:hover {
      background-color: darken($color: #2a7c6f, $amount: 10%);
    }
  }

  .input {
    display: flex;
    flex-direction: column;

    label {
      padding-bottom: 0.5em;
      font-size: 0.9rem;
    }

    input {
      height: 56px;
      width: 150px;
      font-size: 1.4rem;
      line-height: 1.6rem;
      font-weight: 300;
      padding-left: 1rem;
      border: 1px solid rgba($color: #000000, $alpha: 0.1);
      border-radius: 5px;
      outline: none;
      transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);

      &:focus {
        border: 2px solid rgba($color: #2a7c6f, $alpha: 1);
      }
    }
  }

  .input__radio {
    padding: 0.4rem 0;
  }
</style>
